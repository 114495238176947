export const formatDate = (inpDate) => {
  if (!inpDate) return null;

  var shiftDate = new Date(inpDate);

  const year = shiftDate.getFullYear();
  var month = shiftDate.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var date = shiftDate.getDate();
  if (date < 10) {
    date = "0" + date;
  }
  return date + "." + month + "." + year + ".";
};

export const getMonthKeyFromRaw = function(dateRaw) {
  const year = dateRaw.getFullYear();
  var month = dateRaw.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return year + "-" + month;
};

export const monthKeyFromIso = (dateIso) => {
  if (!dateIso) {
    return;
  }
  const [year, month, day] = dateIso.split("-");
  return `${year}-${month}`;
};

export const formatTimeFromSec = (sec) => {
  const closeedAt = new Date(sec * 1000);

  var hours = closeedAt.getHours();
  if (hours < 10) {
    hours = "0" + hours;
  }
  var minutes = closeedAt.getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  return hours + ":" + minutes;
};

export const formatDateTime = (sec) => {
  var date = new Date(sec * 1000);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  var hour = date.getHours();
  var min = date.getMinutes();

  if (min < 10) {
    min = "0" + min;
  }

  return day + "." + month + "." + year + " " + hour + ":" + min;
};

export const getClockFromMs = (ms) => {
  var date = new Date(ms);
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();

  if (min < 10) {
    min = "0" + min;
  }

  if (sec < 10) {
    sec = "0" + sec;
  }

  return hour + ":" + min + ":" + sec;
};

export const calculateVisitDuration = (startAt, endAt) => {
  var secondsPassed = endAt.getTime() / 1000 - startAt.getTime() / 1000;
  //hours
  var hours = parseInt(secondsPassed / 3600);
  secondsPassed = secondsPassed % 3600; // seconds remaining after extracting hours
  //minutes
  var minutes = parseInt(secondsPassed / 60);
  //seconds
  var seconds = Math.floor(secondsPassed % 60);

  return (
    hours +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
};
