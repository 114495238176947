<template>
  <!-- date -->
  <v-dialog
    v-model="datePickerDialog"
    :close-on-content-click="false"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formatDate(date)"
        @input="dateSelected()"
        :label="labelText || $t('form.date')"
        :prepend-icon="!noIcon ? 'event' : ''"
        readonly
        v-on="on"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker
      v-if="!pastToFuture && (minDate || maxDate)"
      v-model="date"
      @input="dateSelected()"
      :first-day-of-week="1"
      :max="maxDate ? maxDate : ''"
      :min="minDate ? minDate : ''"
      :locale="$i18n.locale"
    ></v-date-picker>
    <v-date-picker
      v-else-if="!pastToFuture"
      v-model="date"
      @input="dateSelected()"
      :first-day-of-week="1"
      :max="!future ? today : ''"
      :min="!future ? '' : today"
      :locale="$i18n.locale"
    ></v-date-picker>
    <v-date-picker
      v-else
      v-model="date"
      @input="dateSelected()"
      :first-day-of-week="1"
      :locale="$i18n.locale"
    ></v-date-picker>
  </v-dialog>
</template>

<script>
import { formatDate } from "@/helpers/helperFns";
import { isNil } from "lodash";
export default {
  name: "SelectDate",
  props: [
    "loadedDate",
    "params",
    "noIcon",
    "future",
    "pastToFuture",
    "minDate",
    "maxDate",
    "labelText",
    "disabled",
  ],
  watch: {
    loadedDate: function (newVal, oldVal) {
      // watch it
      if (newVal) {
        this.date = newVal;
      }
      if (isNil(newVal)) {
        this.date = null;
      }
    },
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      today: new Date().toISOString().substr(0, 10),
      datePickerDialog: false,
    };
  },
  created() {
    if (this.loadedDate) {
      this.date = this.loadedDate;
    }
    if (isNil(this.loadedDate)) {
      this.date = null;
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    dateSelected() {
      this.datePickerDialog = false;
      this.$emit("newDate", this.date, this.params);
    },
  },
};
</script>
